import { isMobile } from 'react-device-detect'
import $ from 'jquery'

let Overrides = require('./overrides.' + process.env.NODE_ENV + '.js')

if (
    typeof window !== 'undefined' &&
    window.location.pathname === '/'
) {
    const api = 'https://api.coinbase.com/v2/exchange-rates?currency=BTC';

    $.get(api, p => {
        if (p.data.rates.USD < Infinity && p.data.rates.USD > 0 && p.data.rates.USD !== null && p.data.rates.USD)
            localStorage.setItem('quiz-bitcoin-rate', p.data.rates.USD);
        else
            localStorage.setItem('quiz-bitcoin-rate', 20000);
    }).fail(function() {
        localStorage.setItem('quiz-bitcoin-rate', 20000);
    });
}

const Settings = {
    loaderTimeout: (typeof window !== 'undefined' && window.location.pathname === '/') ? 2000 : 0,
    tutorialTimeout: 2000,
    tutorialStepInterval: 10000,
    showAnimationsOn: true,
    parallaxOn: (!isMobile) ? true : false,
    mobileSwipeEnabled: false,
    startBalance: 20000,
    betterThanFactor: 0.021349,
    emailFormActionURL: "../send-email.php",
    sendGrid: {
        apiKey: Overrides.default.sendgrid.apiKey,
        listId: Overrides.default.sendgrid.listId
    },
    regEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    landingURL: "https://margex.com/app/signup/?rid=83687087&utm_medium=leotraf&utm_campaign=quizeur36",
    twitterURL: "https://twitter.com/margexcom",
    facebookURL: "https://www.facebook.com/margexcom",
    telegramURL: "https://t.me/margex_official",
    RayDalio: {
        imageClass: "-person-1",
        personName: "Ray Dalio",
        text: "You are courageous, curious, and adventurous. Some might say that your trading style is risky but they just don't know what you know. You're not afraid to fail, so not all your trades are profitable, but the ones that are easily cover all your losses and put you over the top.",
        hashName: "ab0f55bf634f30d0d412d96660c7c54d",
        index: 1
    },
    DavidEinhorn: {
        imageClass: "-person-3",
        personName: "David Einhorn",
        text: "Bear is your favorite animal and short selling is your signature move. When others buy you keep selling because you know that the markets fall much faster than they grow. Not every trade brings you profits but if you catch the falling red candle, your efforts are well compensated.",
        hashName: "cea329ac51794839caa39ccfbcf6dbde",
        index: 3
    },
    WarrenBuffet: {
        imageClass: "-person-4",
        personName: "Warren Buffet",
        text: "Other traders describe your trading style as conservative, but in reality, they just don't get what risk management is. While others rush for every trading opportunity you limit your trades to the ones in which you are 100% sure. Steady profits are what really important for you and, well you get what you want.",
        hashName: "80e4ceb7f94a5f530880a67e83eedb46",
        index: 4
    },
    EdwardThorp: {
        imageClass: "-person-7",
        personName: "Edward Thorp",
        text: "When someone asks you why you made the trade you just smile :) For you the financial market is a big field of experiments. Not a lot of people can understand your trading style, but it doesn't mean that it can't bring you good profits. While others",
        hashName: "284058880ccd04d3ad015d7acffe4d9d",
        index: 7
    },
    JohnPaulson: {
        imageClass: "-person-5",
        personName: "John Paulson",
        text: "Although the general public doesn't consider you a rockstar, you are an absolute one among the trading community.  You're the first to spot the early signs of an emerging opportunity, and thanks to proper position management and smart use of your capital, you're not afraid to go the extra mile and defy all odds.",
        hashName: "0c0c2a4369c52a7c19f6b45132545708",
        index: 5
    },
    BillAckman: {
        imageClass: "-person-2",
        personName: "Bill Ackman",
        text: "Your insight goes far beyond that of the average trader. Some say that you can predict the future, but you are just good at comparing facts. You love risk, but you know when to go all in and when to back off, and this strategy brings you big profits!",
        hashName: "f3fba555b7760edb4101b98e6b788037",
        index: 2
    },
    person7Data: {
        imageClass: "-person-6",
        personName: "Person 7",
        text: "Person 7 Text",
        hashName: "998a1ed57d3e8269a2f4b8dd012c600e",
        index: 6
    }
}
export default Settings;
